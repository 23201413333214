import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/Clubs/EcoClub/evcls5/1.jpg';
import p2 from 'assests/Photos/Clubs/EcoClub/evcls5/2.jpg';
import p3 from 'assests/Photos/Clubs/EcoClub/evcls5/3.jpg';
import p4 from 'assests/Photos/Clubs/EcoClub/evcls5/4.jpg';
import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';

import SideHealth from 'views/HealthAndWellnesClub/SideHealth';

import { Typography } from '@mui/material';
import Container from 'components/Container';


const Ev_Day_Class5_2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },


  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      Beat Plastic Pollution
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 5  &  Date: 09.06.2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        As a part of the World Environment Day celebrations on Monday, 05 June 2023, students of class 5
participated in an activity – collage making. Keeping the theme of this year ‘Beat Plastic Pollution’ in
mind, the students displayed a range of activities to raise awareness about the detrimental impact of
plastic pollution on the environment and promote actions to reduce plastic waste. The students got an
opportunity to express their ideas to safe guard the Planet Earth from being polluted by avoiding
unnecessary and single use plastics. Thus learnt to say a firm ‘NO’ to disposable plastic cutlery, plastic
straws and other single use plastics.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
Handle with care, plastic is everywhere.

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Ecoclub2023 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default Ev_Day_Class5_2023;